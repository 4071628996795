import React, { useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import themeDark from "assets/theme-dark";

import App from "./App";
import PublicRoutes from "routes/PublicRoutes";
import { useAppDispatch, useAppSelector } from "./hooks/hooks";
import { Notification } from "./components/Notification";
import { clearMessage } from "./features/globalSlice";
import { useLocation } from "react-router-dom";
import { triggerPageChange } from "./utils/tagManagerTriggers";
import { FlexColumn } from "./components/design-system/Flex/Flex";
import SpinnerBackdrop from "./components/SpinnerBackdrop";

const AppWrapper = styled(FlexColumn)`
  overflow: hidden;
  flex: 1 1 auto;
  position: relative;
`;

const Public = () => {
  const {
    loading,
    notification: { message, type },
  } = useAppSelector((state) => state.global);

  const { isLoggedIn } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const location = useLocation();

  // Display global notification
  useEffect(() => {
    if (message) {
      Notification({
        content: message,
        type,
      });
      dispatch(clearMessage());
    }
  }, [message]);

  useEffect(() => {
    triggerPageChange(location.pathname);
  }, [location]);

  return (
    <MuiThemeProvider theme={themeDark}>
      <ThemeProvider theme={theme}>
        <SpinnerBackdrop loading={loading} />
        <AppWrapper>{isLoggedIn ? <App /> : <PublicRoutes />}</AppWrapper>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default Public;
