import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import HowToRegIcon from "@mui/icons-material/HowToReg";

const sidebarSettings = (isOrganizer: boolean) => ({
  menu: [
    {
      type: "logo",
      key: "logo",
    },
    {
      type: "divider",
      key: "divider1",
    },
    {
      type: "link",
      key: "events",
      title: "events",
      route: "/events",
    },
    {
      type: "link",
      key: "about-us",
      title: "about us",
      route: "/about-us",
    },
    {
      type: "button",
      key: "create-event",
      title: `${isOrganizer ? "events dashboard" : "create event" }`,
      route: `${process.env.REACT_APP_ORGANIZER_URL}`,
    },
    {
      type: "divider",
      key: "divider2",
    },
    {
      type: "profile",
      key: "user-profile",
      authenticated: true,
    },
    {
      type: "item",
      key: "account-details",
      title: "account details",
      icon: <AccountCircleIcon color="inherit" fontSize="medium" />,
      route: "/account/details",
      authenticated: true,
    },
    {
      type: "item",
      key: "orders",
      title: "orders",
      icon: <ShoppingBasketIcon color="inherit" fontSize="medium" />,
      route: "/account/orders",
      authenticated: true,
    },
    {
      type: "divider",
      key: "divider3",
      authenticated: true,
    },
    {
      type: "item",
      key: "logout",
      title: "logout",
      icon: <LogoutIcon color="inherit" fontSize="medium" />,
      route: "/logout",
      authenticated: true,
    },
    {
      type: "item",
      title: "Log in",
      key: "login",
      icon: <LoginIcon color="inherit" fontSize="medium" />,
      route: "/sign-in",
      authenticated: false,
    },
    {
      type: "item",
      title: "Create account",
      key: "create-account",
      icon: <HowToRegIcon color="inherit" fontSize="medium" />,
      route: "/sign-up",
      authenticated: false,
    },
  ],
})

export default sidebarSettings;
