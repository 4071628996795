import { Outlet } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import styled from "styled-components";
import {Flex, FlexColumn} from "components/design-system/Flex/Flex";
import { useMobileDetect } from "../../hooks/hooks"
import ChatBot from "../../components/ChatBot/ChatBot";

const MainLayoutWrapper = styled(FlexColumn)`
  overflow: auto;
  flex: 1 1 auto;
`;

const ContentWrapper = styled(FlexColumn)<{isMobile: boolean}>`
  margin-top: ${({isMobile}) => isMobile ? '75px' : '80px'};
  margin-bottom: ${({isMobile}) => isMobile ? '186px' : '98px'};
  `;



const MainLayout = () => {
  const isMobile = useMobileDetect(980);

  return (
    <MainLayoutWrapper>
      <Navbar />
      <ContentWrapper isMobile={isMobile}>
        <Outlet />
      </ContentWrapper>
        <ChatBot/>
      <Footer />
    </MainLayoutWrapper>
  );
};

export default MainLayout;
