
const colors = {
  purple: "#7f5af0",
  green: "#66baa8",
  white: "#ffffff",
  mainBlue: "#31313A"
};

export enum BREAKPOINTS { MOBILE = 0, TABLET = 768, LAPTOP = 1024, DESKTOP = 1440 }

const theme = {
  colors,
  breakpoints: {
    values: BREAKPOINTS,
    up: (breakpoint: BREAKPOINTS) => `@media (min-width:${breakpoint}px)`,
    down: (breakpoint: BREAKPOINTS) => `@media (max-width:${breakpoint}px)`,
    between: (start: BREAKPOINTS, end: BREAKPOINTS) => `@media (min-width:${start}px) and (max-width:${end}px)`,
  }
};

export default theme;
