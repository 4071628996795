import React from "react";

import "./style.scss";
import Maintenance from "../../assets/illustrations/maintenance.png";
import MDButton from "../../uikit/MDButton";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="error-page">
      <img src={Maintenance} alt="Error" />

      <div className="error-page__info">
        <h1>Something went wrong or this page doesn't exist !</h1>
      </div>

      <MDButton color="primary" type="submit" component={Link} to="/">
        go to home page
      </MDButton>
    </div>
  );
};
export default Error;
