import styled from "styled-components"
import { Flex } from "../design-system/Flex/Flex"
import { BREAKPOINTS } from "utils/theme"


export const ChatButton = styled(Flex)<{$active: boolean}>`
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 100px;
  height: 100px;
  z-index: 1000;
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 50%;
  
  img {
    max-width: 70%;
    max-height: 70%;
    transform: ${({$active}) => $active ? 'scale(1.1)' : 'scale(1)'};
    
    &:hover {
      transform: scale(1.1);
      transition: transform 0.2s ease-in-out;
    }
  }
`;

export const IframeWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 130px;
  right: 20px;
  width: 480px;
  height: 700px;
  z-index: 1100;
  border-radius: 15px;
  padding: 20px 10px;
  margin: 0 15px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  background-color: white;

  ${({ theme }) => theme.breakpoints.down(BREAKPOINTS.TABLET)} {
    width: 80%;
    height: 55%;
  }
`;

export const StyledIframe = styled.iframe<{$hidden: boolean}>`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 15px;
  display: ${({$hidden}) => $hidden ? 'none' : 'block'};
`;
