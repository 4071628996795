import { FC, ReactNode, forwardRef } from "react";

// @mui material components
import { ButtonProps, CircularProgress } from "@mui/material";

// Custom styles for MDButton
import MDButtonRoot from "uikit/MDButton/MDButtonRoot";
import styled from "styled-components"

// Material Dashboard 2 PRO React TS contexts

// Declaring props types for MDButton
interface Props extends Omit<ButtonProps, "color" | "variant"> {
  color?:
    | "white"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "green"
    | "default";
  variant?: "text" | "contained" | "outlined" | "gradient";
  size?: "small" | "medium" | "large";
  circular?: boolean;
  iconOnly?: boolean;
  children?: ReactNode;
  [key: string]: any;
}

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  `;

const MDButton: FC<Props> = forwardRef(
  ({ color, variant, size, circular, iconOnly, children, loading, ...rest }, ref) => {
    const darkMode = "false"; // TODO Use context in order to get the value

    return (
      <MDButtonRoot
        {...rest}
        ref={ref}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
      >
        {loading ? <CircularProgress size={20} color="secondary"/> : children}
      </MDButtonRoot>
    );
  }
);

// Declaring default props for MDButton
MDButton.defaultProps = {
  color: "white",
  variant: "contained",
  size: "medium",
  circular: false,
  iconOnly: false,
};

export default MDButton;
