import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SpinnerBackdrop from "../components/SpinnerBackdrop";
import MainLayout from "../layouts/MainLayout";
import { useAppSelector } from "../hooks/hooks";

//Routes

const Event = lazy(() => import("pages/Event"));
const Home = lazy(() => import("pages/Home"));
const SignIn = lazy(() => import("pages/Auth/SignIn"));
const SignUp = lazy(() => import("pages/Auth/SignUp"));
const TermsConditions = lazy(() => import("pages/TermsConditions"));
const EmailConfirmation = lazy(() => import("pages/Auth/EmailConfirmation"));
const ForgotPassword = lazy(() => import("pages/Auth/PasswordRecover/ForgotPassword"));
const ResetPassword = lazy(() => import("pages/Auth/PasswordRecover/ResetPassword"));
const Events = lazy(() => import("pages/Events"));
const ContactUs = lazy(() => import("pages/ContactUs/ContactUs"));
const Error = lazy(() => import("pages/Error"));
const Main = lazy(() => import("pages/Main"));
const PaymentStatus = lazy(() => import("pages/PaymentStatus"));
const Oauth2Redirect = lazy(() => import("pages/Auth/Oauth2Redirect"));
const MainRedesign = lazy(() => import("pages/MainRedesign/MainRedesign"));
const TermsConditionsStartupMD = lazy(() => import("pages/TermsConditionsDreamUps"));

const PublicRoutes = () => {

  return (
    <Suspense fallback={<SpinnerBackdrop />}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<MainRedesign />} />
          <Route path="home" element={<Home />} />
          <Route path="oauth2/redirect" element={<Oauth2Redirect />} />
          <Route path="event/:id" element={<Event />} />
          <Route path="events" element={<Events />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/terms-conditions/startup-moldova" element={<TermsConditionsStartupMD />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/email-confirmation" element={<EmailConfirmation />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/error" element={<Error />} />
          <Route path="/payment" element={<PaymentStatus />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default PublicRoutes;
