import request from "utils/request";
import { IGetOrderSummaryPayload, IOrderPayload } from "../types/order";
import { IRegistrationFormSubmission } from "../types/event"

interface IOfferUpdate {
  active: boolean;
}

export const getEvent = (id: number) => {
  return request({
    url: `/events/${id}`,
    method: "get",
  });
};

export const placeOrder = (eventId: number, payload: IOrderPayload) => {
  return request({
    url: `events/${eventId}/orders`,
    method: "post",
    data: payload,
  });
};

export const getAllEvents = (params?: object) => {
  return request({
    url: "/events",
    method: "get",
    params
  });
};

export const getOfferDetails = (offerId: number) => {
  return request({
    url: `/offers/${offerId}`,
    method: "get",
  });
};

export const updateOfferActivationStatus = (offerId: number, data: IOfferUpdate) => {
  return request({
    url: `/offers/${offerId}`,
    method: "post",
    data,
  });
};

export const getOrderDetails = (orderId: number) => {
  return request({
    url: `/orders/${orderId}`,
    method: "get",
  });
};

export const getCouponDetails = (eventId: number, couponCode: string) => {
  return request({
    url: `/events/${eventId}/tickets?coupon-code=${couponCode}`,
    method: "get",
  });
};

export const getOrderSummary = (id: number, data: IGetOrderSummaryPayload) => {
  return request({
    url: `/events/${id}/orders/summary`,
    method: "post",
    data,
  });
};

export const getTrackersDetails = (id: number) => {
  return request({
    url: `/events/${id}/trackers`,
    method: 'get',
  })
}


export const getEventRegistrationForm = (eventId: number) => {
  return request({
    url: `/events/${eventId}/registrationForms`,
    method: "get",
  });
}

export const submitEventRegistrationForm = (eventId: number, registrationFormId: number, data: IRegistrationFormSubmission) => {
  return request({
    url: `/events/${eventId}/registrationForms/${registrationFormId}`,
    method: "post",
    data,
  });
}
