import Divider from "@mui/material/Divider";
import "./style.scss";
import { NavLink } from "react-router-dom";
import MDAvatar from "uikit/MDAvatar";
import { ISidebarItem, ISidebarSettingsProps } from "types/sidebar";
import Logo from "assets/unde/header-log.png";
import MDButton from "uikit/MDButton";
import React from "react";
import { useAppSelector } from "../../hooks/hooks";

interface ISidebarProps {
  onItemSelect?: () => void; // TODO Use this in order to close menu on mobile
  settings: ISidebarSettingsProps;
}

const SideBar = ({ onItemSelect, settings }: ISidebarProps) => {
  const { token } = useAppSelector((state) => state.auth);
  const { details } = useAppSelector((state) => state.user);

  const handleMenuItemClick = (menuItem: ISidebarItem) => {
    onItemSelect && onItemSelect();
  };

  const renderMenuItem = (item: ISidebarItem) => {
    let sidebarItem;

    //Avoid divider omit while rendering
    if (typeof item.authenticated === "boolean") {
      if ((item.authenticated && !token) || (!item.authenticated && token)) {
        return;
      }
    }


    if (item.type === "divider") {
      sidebarItem = <Divider key={item.key} />;
    }

    if (item.type === "link" || item.type === "item") {
      sidebarItem = (
        <NavLink
          to={item.route}
          key={item.key}
          className={({ isActive }) =>
            `sidebar-container__menu-item ${isActive && "sidebar-container__menu-item--active"}
            ${item.type === "link" && "sidebar-container__menu-item--link"}
            `
          }
          onClick={() => handleMenuItemClick(item)}>
          {item.icon && item.icon}
          <p>{item.title}</p>
        </NavLink>
      );
    }

    if (item.type === "profile") {
      const userName = details?.name ? details.name : "Guest";
      sidebarItem = (
        <div
          className="sidebar-container__menu-item sidebar-container__menu-item--bold"
          key={item.key}>
          <MDAvatar size="xs" bgColor="secondary">
            {userName[0]}
          </MDAvatar>
          <p>{userName}</p>
        </div>
      );
    }
    if (item.type === "logo") {
      sidebarItem = (
        <div
          className="sidebar-container__menu-item sidebar-container__menu-item--img"
          key={item.key}>
          <img src={Logo} alt="logo" />
        </div>
      );
    }

    if (item.type === "button") {
      sidebarItem = (
        <div
          className="sidebar-container__menu-item sidebar-container__menu-item--button"
          key={item.key}>
          <MDButton color="green" component="a" href={item.route} target="_blank" rel="noreferrer">
            {item.title}
          </MDButton>
        </div>
      );
    }
    return sidebarItem;
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar-container__menu">
        {settings && settings.menu.map((item) => renderMenuItem(item))}
      </div>
    </div>
  );
};

export default SideBar;
