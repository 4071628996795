import React from "react";
import "./style.scss";
import logo from "assets/unde/header-log.png";
import { Link } from "react-router-dom";
import MDButton from "uikit/MDButton";
import { ReactComponent as UndeLogo } from "assets/unde/unde_light.svg";
import { useAppSelector } from "hooks/hooks";

const DesktopNav = () => {
  const { token } = useAppSelector((state) => state.auth);
  const { details } = useAppSelector((state) => state.user);
  const actionButtonName = details?.isPartOfOrganization ? "Events Dashboard" : "Create Event"

  return (
    <div className="desktop-navbar">
      <div className="container d-flex align-center">
        <Link to="/">
          <UndeLogo />
        </Link>
        <div className="desktop-navbar-items">
          <ul>
            <Link to="/events">Events</Link>
            <Link to="/contact-us">Contact us</Link>
          </ul>
        </div>

        <div className="desktop-navbar-menu">
          <MDButton
            color="green"
            component="a"
            href={`${process.env.REACT_APP_ORGANIZER_URL}`}
            target="_blank"
            rel="noreferrer">
            {actionButtonName}
          </MDButton>
          {token ? (
            <ul>
              <Link to="/account/orders">My Account</Link>
            </ul>
          ) : (
            <ul>
              <Link to="/sign-in">Login</Link>
              <Link to="/sign-up">Create account</Link>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default DesktopNav;
