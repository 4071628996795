import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ILoginPayload, IRegisterPayload } from "types/auth";
import { login, register } from "../api/auth";
import { setMessage, setLoading } from "./globalSlice";
import { clearUserDetails } from "./userSlice";
import { AppThunk } from "../store";

interface AuthState {
  isLoggedIn: boolean;
  error: string;
  token: string;
}

interface ILoginResponse {
  jwt: string;
}

export const loginUser = createAsyncThunk<
  ILoginResponse,
  ILoginPayload,
  { rejectValue: AxiosError }
>("auth/login", async (credentials: ILoginPayload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(setLoading(true));
    const response = await login(credentials);
    const { status, data } = response;
    const token = data.jwt;
    if (status === 200) {
      dispatch(setToken(token));
    }
    dispatch(
      setMessage({
        type: "success",
        message: "You have been successfully authenticated !",
      })
    );
    return data;
  } catch (err) {
    const error = err as AxiosError;

    if (error.response.status === 412) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Please confirm your email address and try again !",
        })
      );
    } else {
      dispatch(
        setMessage({
          type: "error",
          message: "There was an error while logging in ! Please try again !",
        })
      );
    }

    return rejectWithValue(error);
  } finally {
    dispatch(setLoading(false));
  }
});

export const registerUser = createAsyncThunk<
  IRegisterPayload,
  IRegisterPayload,
  { rejectValue: AxiosError }
>("auth/register", async (registrationData: IRegisterPayload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(setLoading(true));
    const { data } = await register(registrationData);
    dispatch(setLoading(false));

    return data;
  } catch (err) {
    dispatch(setLoading(false));
    const error = err as AxiosError;
    dispatch(
      setMessage({
        type: "error",
        message: error.message,
      })
    );
    return rejectWithValue(error);
  }
});

export const logoutUser = (withNotification = true): AppThunk => {
  return (dispatch) => {
    dispatch(clearUserDetails());
    dispatch(clearAuthState());
    if (withNotification) {
      dispatch(
        setMessage({
          type: "success",
          message: "You was successful logged out !",
        })
      );
    }
  };
};
const initialState: AuthState = {
  isLoggedIn: Boolean(localStorage.getItem("token")),
  token: localStorage.getItem("token"),
  error: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },

    clearAuthState: () => {
      localStorage.removeItem("token");
      return {
        isLoggedIn: false,
        token: null,
        error: "",
      };
    },
    setToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
      state.isLoggedIn = true;
      localStorage.setItem("token", payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state) => {
      state.isLoggedIn = true;
    });
    builder.addCase(loginUser.rejected, (state, { payload }: PayloadAction<AxiosError>) => {
      state.error = payload.message;
      state.isLoggedIn = false;
    });
  },
});

const { actions, reducer } = authSlice;

export const { setErrors, clearAuthState, setToken } = actions;

export const authSelector = (state: { authStore: AuthState }) => state.authStore;

export default reducer;
