import { getUserInfo } from "../api/user";
import { AxiosError } from "axios";
import { IUser, UserDetails } from "../types/user";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IUser = {
  details: null,
  loadingDetails: false,
  error: null,
};

export const getUserDetails = createAsyncThunk<UserDetails, null, { rejectValue: AxiosError }>(
  "user/getUserDetails",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await getUserInfo();
      localStorage.setItem("user", JSON.stringify(data));
      dispatch(setUserDetails(data));
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails(state, { payload }: PayloadAction<UserDetails>) {
      state.details = payload;
    },
    clearUserDetails(state) {
      localStorage.removeItem("user");
      localStorage.removeItem("organizerDetails")
      state.details = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.pending, (state) => {
      state.loadingDetails = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state) => {
      state.loadingDetails = false;
    });

    builder.addCase(getUserDetails.rejected, (state, { payload }: PayloadAction<AxiosError>) => {
      state.error = payload;
      state.loadingDetails = false;
    });
  },
});

const { reducer, actions } = userSlice;

export const { setUserDetails, clearUserDetails } = actions;

export const eventsSelector = (state: { userStore: UserDetails }) => state.userStore;

export default reducer;
