import { configureStore, combineReducers, Action, ThunkAction } from "@reduxjs/toolkit";

import global from "features/globalSlice";
import user from "features/userSlice";
import eventOrder from "features/eventOrderSlice";
import auth from "features/authSlice";

const rootReducer = combineReducers({
  global,
  user,
  eventOrder,
  auth,
});

export const store = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
    devTools: process.env.NODE_ENV === "development",
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof store>;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
