import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  IGetOrderSummaryPayload, IInvoiceForm,
  IOrderedTicket,
  IOrderPayload,
  IOrderSummary,
} from "../types/order"
import { RootState } from "../store"
import {
  IEvent,
  IExtendedTicketType,
  IRegistrationFormSubmission,
} from "types/event"
import { AxiosError } from "axios"
import { getEvent, getOrderSummary, placeOrder } from "../api/event"
import { IUserBillingAddress } from "../types/user"
import { OrderStep } from "../constants/payment"
import { setLoading, setMessage } from "./globalSlice"
import { ICustomError } from "../utils/request"
import { IOrganizerAgreement } from "../types/organizer"
import { PaymentMethods } from "../constants/paymentMethods"

export interface IFormsSubmissionStatus {
  invoiceDetailsForm: boolean;
  registrationForm: boolean;
  ticketsReceiversForm: boolean;
}

interface IOrderPlacementState {
  current: OrderStep;
  previous: OrderStep;
}

interface EventOrder {
  event: IEvent;
  loading: boolean;
  selectedTickets: IExtendedTicketType[];
  orderSummary: IOrderSummary;
  order: IOrderPayload;
  error: AxiosError;
  orderPlacementState: IOrderPlacementState;
  organizerAgreement: IOrganizerAgreement;
  formsSubmissionStatus: IFormsSubmissionStatus;
}


export interface IPlaceOrderResponse {
  paymentUrl?: string;
  id: number;
  status: string;
  saleOfferId?: number;
}

const initialState: EventOrder = {
  event: null,
  loading: false,
  selectedTickets: [],
  orderSummary: null,
  error: null,
  organizerAgreement: null,
  order: {
    orderTicketTypes: [], // Could be avoided
    couponCode: null,
    paymentMethod: PaymentMethods.CREDIT_CARD,
    bankTransferDetails: null,
    sendTicketsSeparately: false,
  },
  orderPlacementState: {
    current: OrderStep.SUMMARY,
    previous: null,
  },
  formsSubmissionStatus: {
    invoiceDetailsForm: false,
    registrationForm: false,
    ticketsReceiversForm: false,
  }
};

export const getEventDetails = createAsyncThunk<IEvent, number, { rejectValue: AxiosError }>(
  "eventOrder/getEventDetails",
  async (eventId, { rejectWithValue, dispatch }) => {
    try {
      dispatch(clearState());
      const { data } = await getEvent(eventId);
      return data;
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error);
    }
  }
);

export const getUserOrderSummary = createAsyncThunk<
  IOrderSummary,
  IGetOrderSummaryPayload,
  { rejectValue: AxiosError }
>("eventOrder/getUserOrderSummary", async (payload, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await getOrderSummary(payload.eventId, payload);
    dispatch(setOrderSummary(data));
    return data;
  } catch (err) {
    const error = err as AxiosError;
    return rejectWithValue(error);
  }
});

export const placeEventOrder = createAsyncThunk<
  IPlaceOrderResponse,
  null,
  { rejectValue: AxiosError }
>("eventOrder/placeOrder", async (_, { rejectWithValue, getState, dispatch }) => {
  try {
    dispatch(setLoading(true));
    const {
      eventOrder: { order, event },
    } = getState() as RootState;
    const { data } = await placeOrder(event.id, order);
    if (!data.paymentUrl) {
      dispatch(setLoading(false));
    }
    return data;
  } catch (err) {
    const validationMessage = (err as AxiosError<ICustomError>).response.data.errors[0].message;
    const errorMessage =
      validationMessage || "There was an error while placing your order ! Please try again later !";
    dispatch(
      setMessage({
        type: "error",
        message: errorMessage,
      })
    );
    dispatch(setLoading(false));

    const error = err as AxiosError;
    return rejectWithValue(error);
  }
});

const eventOrderSlice = createSlice({
  name: "setEventDetails",
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    setEventDetails(state, { payload }: PayloadAction<IEvent>) {
      state.event = payload;
    },
    setSelectedTickets(state, { payload }: PayloadAction<IExtendedTicketType[]>) {
      state.selectedTickets = payload;
    },
    incrementTicketCount(state, { payload }: PayloadAction<number>) {
      state.selectedTickets = state.selectedTickets.map((item) =>
        item.id === payload ? { ...item, count: ++item.count } : item
      );
    },
    decrementTicketCount(state, { payload }: PayloadAction<number>) {
      state.selectedTickets = state.selectedTickets.map((item) =>
        item.id === payload && item.count > 0 ? { ...item, count: --item.count } : item
      );
    },
    setOrderSummary(state, { payload }: PayloadAction<IOrderSummary>) {
      state.orderSummary = payload;
    },
    setCurrentOrderPlacementState(state, { payload }: PayloadAction<OrderStep>) {
      state.orderPlacementState.current= payload;
    },
    setPreviousOrderPlacementState(state, { payload }: PayloadAction<OrderStep>) {
      state.orderPlacementState.previous = payload;
    },
    setOrderedTickets(state, { payload }: PayloadAction<IOrderedTicket[]>) {
      state.order.orderTicketTypes = payload;
    },
    setOrderCouponCode(state, {payload}: PayloadAction<string>) {
      state.order.couponCode = payload
    },
    setOrganizerAgreement(state, {payload}: PayloadAction<IOrganizerAgreement>) {
      state.organizerAgreement = payload
    },
    setLoadingEvent(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
    setOrderPaymentMethod(state, { payload }: PayloadAction<PaymentMethods>) {
      state.order.paymentMethod = payload;
    },
    setBankTransferDetails(state, { payload }: PayloadAction<IInvoiceForm>) {
      state.order.bankTransferDetails = payload;
    },
    setSendTicketsSeparately(state, { payload }: PayloadAction<boolean>) {
      state.order.sendTicketsSeparately = payload;
    },
    setRegistrationFormData(state, { payload }: PayloadAction<IRegistrationFormSubmission>) {
      state.order.registrationFormResponse = payload;
    },
    setFormsSubmissionStatus(state, { payload }: PayloadAction<Partial<IFormsSubmissionStatus>>) {
      state.formsSubmissionStatus = { ...state.formsSubmissionStatus, ...payload };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEventDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getEventDetails.fulfilled, (state, { payload }) => {
      state.event = payload;
      state.loading = false;
    });

    builder.addCase(getEventDetails.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(placeEventOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(placeEventOrder.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(placeEventOrder.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(getUserOrderSummary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserOrderSummary.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getUserOrderSummary.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

const { reducer, actions } = eventOrderSlice;

export const {
  setLoadingEvent,
  clearState,
  setEventDetails,
  setSelectedTickets,
  incrementTicketCount,
  decrementTicketCount,
  setOrderSummary,
  setCurrentOrderPlacementState,
  setPreviousOrderPlacementState,
  setOrderedTickets,
  setOrderCouponCode,
  setOrganizerAgreement,
  setOrderPaymentMethod,
  setBankTransferDetails,
  setSendTicketsSeparately,
  setRegistrationFormData,
  setFormsSubmissionStatus
} = actions;

export const eventOrderSelector = (state: { eventOrderStore: EventOrder }) => state.eventOrderStore;

export default reducer;
