import "./style.scss";
import master from "../../assets/icons/creditcards/mc_symbol.png";
import visa from "../../assets/icons/creditcards/visa.png";
import {ReactComponent as FacebookIcon} from "../../assets/icons/socials/facebook.svg";
import {ReactComponent as InstagramIcon} from "../../assets/icons/socials/instagram.svg";
import {ReactComponent as LinkedinIcon} from '../../assets/icons/socials/linkedin.svg';
import { Link } from "react-router-dom";
import styled from "styled-components"
import { Flex } from "../design-system/Flex/Flex"

enum Socials  {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKEDIN"
}

const SocialIconWrapper = styled(Flex)`
  cursor: pointer;
  padding: 0 5px;
`;

const Footer = () => {

  const handleSocialClick = (social: Socials) => {
    let link;
    switch (social) {
      case Socials.INSTAGRAM:
        link = "https://www.instagram.com/unde.events/"
        break;
      case Socials.FACEBOOK:
        link = "https://www.facebook.com/unde.official"
        break;
      case Socials.LINKEDIN:
        link = "https://www.linkedin.com/company/unde/"
        break;
      default:
        link = "https://www.instagram.com/unde.events/"
    }

    window.open(link, "_blank").focus();
  }
  return (
    <div className="footer">
      <div className="container flex-column">
        <div className="footer__top">
          <div className="footer__top-cards">
            <img src={master} alt="Master Card" />
            <img src={visa} alt="Visa" />
          </div>
          <ul>
            <li>
              <Link to="/terms-conditions">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/terms-conditions?section=privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/contact-us">Contacts</Link>
            </li>
          </ul>
        </div>

        <div className="footer__bottom">
          <p className="footer__bottom-copyright">© Copyrights unde.io | All Rights Reserved</p>
          <div className="footer__bottom-socials">
            <SocialIconWrapper onClick={() => handleSocialClick(Socials.FACEBOOK)}>
              <FacebookIcon />
            </SocialIconWrapper>
            <SocialIconWrapper onClick={() => handleSocialClick(Socials.INSTAGRAM)}>
              <InstagramIcon />
            </SocialIconWrapper>
            <SocialIconWrapper>
              <LinkedinIcon onClick={() => handleSocialClick(Socials.LINKEDIN)}/>
            </SocialIconWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
