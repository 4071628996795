import { GTMOrderedTicketType } from "./tagManagerFunctions"
import { IOrganization } from "../types/event"

interface IContents {
  id: number;
  quantity: number;
}

export const triggerAddToCart = (
  eventId: number,
  total: number,
  currency: string,
  couponCode: string,
  eventName: string,
  orderedTicketTypes: GTMOrderedTicketType[],
  organization: IOrganization
) => {
  window.dataLayer.push({
    event: "buy-tickets",
    ecommerce: {
      brand: organization?.name ?? null,
      brand_id: organization?.id ?? null,
      event_id: eventId,
      value: total,
      coupon: couponCode ?? null,
      currency: currency,
      content_type: 'product',
      content_name: eventName,
      contents: [
        {
          id: eventId,
          quantity: 1
        }],
      items: orderedTicketTypes
    }
  })
};

export const triggerAddPaymentInfo =  (
  eventId: number,
  total: number,
  currency: string,
  couponCode: string,
  eventName: string,
  orderedTicketTypes: GTMOrderedTicketType[],
  organization: IOrganization

) => {
  window.dataLayer.push({
    event: "proceed-to-payment",
    ecommerce: {
      brand: organization?.name ?? null,
      brand_id: organization?.id ?? null,
      event_id: eventId,
      value: total,
      coupon:  couponCode ?? null,
      currency: currency,
      content_type: 'product',
      content_name: eventName,
      contents: [
        {
          id: eventId,
          quantity: 1
        }],
      items: orderedTicketTypes
    }
  })
};

export const triggerEventPageView =  ( eventId: number,
  total: number,
  currency: string,
  eventName: string,
  couponCode: string,
  orderedTicketTypes: GTMOrderedTicketType[],
  organization: IOrganization

) => {
  window.dataLayer.push({
    event: "event-page-visit",
    ecommerce: {
      brand: organization?.name ?? null,
      brand_id: organization?.id ?? null,
      event_id: eventId,
      value: total,
      coupon:  couponCode.length > 0 ? couponCode : null,
      currency: currency,
      content_type: 'product',
      content_name: eventName,
      contents: [
        {
          id: eventId,
          quantity: 1
        }],
      items: orderedTicketTypes
    }
  })
}

export const triggerSuccessPurchase = (
  eventId: number,
  orderId: number,
  total: number,
  currency: string,
  eventName: string,
  couponCode: string,
  orderedTicketTypes: GTMOrderedTicketType[],
  organization: IOrganization

) => {
  window.dataLayer.push({
    event: "success-purchase",
    ecommerce: {
      brand: organization?.name ?? null,
      brand_id: organization?.id ?? null,
      event_id: eventId,
      transaction_id: orderId,
      value: total,
      coupon:  couponCode ?? null,
      currency: currency,
      content_type: 'product',
      content_name: eventName,
      contents: [
        {
          id: eventId,
          quantity: 1
        }],
      items: orderedTicketTypes
    }
  })
}

export const triggerInitiateCheckout = (
  eventId: number,
  total: number,
  currency: string,
  couponCode: string,
  eventName: string,
  orderedTicketTypes: GTMOrderedTicketType[],
  organization: IOrganization
) => {
  window.dataLayer.push({
    event: "checkout-click",
    ecommerce: {
      brand: organization?.name ?? null,
      brand_id: organization?.id ?? null,
      event_id: eventId,
      value: total,
      coupon:  couponCode ?? null,
      currency: currency,
      content_type: 'product',
      content_name: eventName,
      contents: [
        {
          id: eventId,
          quantity: 1
        }],
      items: orderedTicketTypes
    }
  })
};

export const triggerPageChange = (page: string) => {
  window.dataLayer.push({
    event: "page-view",
    page_location: location.origin + page,
    page_referrer: sessionStorage.getItem("page_referrer"),
  })
}
