export enum PaymentStatuses {
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  AWAITING_CONFIRMATION = "AWAITING_CONFIRMATION",
  PAID = "PAID",
  REFUNDED = "REFUNDED",
  CANCELED = "CANCELED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
}

export enum OrderStep {
  SUMMARY = "SUMMARY",
  DEFINE_TICKETS_RECEIVERS = "DEFINE_TICKETS_RECEIVERS",
  REGISTRATION_FORM = "REGISTRATION_FORM",
  INVOICE_DETAILS = "INVOICE_DETAILS",
}
