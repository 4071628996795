import React, { useState } from 'react';
import { ChatButton, StyledIframe, IframeWrapper} from "./ChatBot.styles"
import { CircularProgress } from "@mui/material";
import CostumerServiceIcon from 'assets/icons/customer-service-icon.png'
import CloseIcon from 'assets/icons/down-arrow.png'
import { FlexColumn } from "../design-system/Flex/Flex"

const ChatBot = () => {
    const [isChatVisible, setChatVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    const toggleChat = () => {
        setChatVisible(!isChatVisible);
    };

    const handleIframeLoaded = () => {
      setLoading(false)
    }
    return (
        <FlexColumn>
            <ChatButton $active={isChatVisible} onClick={toggleChat}>
                <img src={isChatVisible ? CloseIcon : CostumerServiceIcon} alt={'chat-icon'} />
            </ChatButton>

            {isChatVisible && (
                <IframeWrapper >
                  {loading && <CircularProgress />}
                  <StyledIframe
                    onLoad={handleIframeLoaded}
                    src="https://bizchat.io/agents/clq1636vq005phdf91ljcsoeu/iframe"
                    title="BizChat"
                    $hidden={loading}
                  />
                </IframeWrapper>
            )}
        </FlexColumn>
    );
}

export default React.memo(ChatBot)
