import request from "utils/request";
import { IForgotPassword, IResetPasswordPayload } from "../types/auth";
import { IOrganizer } from "../types/organizer";
import { IUpdateUserDetails, IUpdateUserPassword } from "../types/user";

export const getUserOrderById = (id: number) => {
  return request({
    url: `users/${id}/orders`,
    method: "get",
  });
};

export const getUserInfo = () => {
  return request({
    url: "users/principal",
    method: "get",
  });
};

export const getOrderTicket = (orderId: number, uuid: string) => {
  return request({
    url: `/orders/${orderId}/tickets/${uuid}`,
    method: "get",
    responseType: "arraybuffer",
  });
};

export const getAllOrderTickets = (orderId: number) => {
  return request({
    url: `/orders/${orderId}/tickets`,
    method: "get",
    responseType: "arraybuffer",
  });
};

export const resetPasswordRequest = (payload: IForgotPassword) => {
  return request({
    url: `/users/password/reset`,
    method: "post",
    data: payload,
  });
};

export const resetPassword = (payload: IResetPasswordPayload) => {
  return request({
    url: "/users/password/reset",
    method: "put",
    data: payload,
  });
};

export const applyOrganizer = (payload: IOrganizer) => {
  return request({
    url: "/organizer/registration",
    method: "post",
    data: payload,
  });
};


export const updateUserInfo = (userDetails: IUpdateUserDetails) => {
  return request({
    url: "users/info",
    method: "post",
    data: userDetails,
  });
};

export const updateUserPassword = (
  updatePassword: Omit<IUpdateUserPassword, "confirmNewPassword">
) => {
  return request({
    url: "users/password",
    method: "post",
    data: updatePassword,
  });
};
