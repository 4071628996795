import { Route, Routes, Navigate } from "react-router-dom";
import React, { lazy, Suspense, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./hooks/hooks";
import { getUserDetails } from "./features/userSlice";
import SpinnerBackdrop from "./components/SpinnerBackdrop";

// Pages & layouts
const Home = lazy(() => import("pages/Home"));
const Error = lazy(() => import("pages/Error"));
const MainLayout = lazy(() => import("layouts/MainLayout"));
const Event = lazy(() => import("pages/Event"));
const AccountLayout = lazy(() => import("layouts/AccountLayout"));
const PaymentStatus = lazy(() => import("pages/PaymentStatus"));
const Orders = lazy(() => import("pages/Orders"));
const TermsConditions = lazy(() => import("pages/TermsConditions"));
const Events = lazy(() => import("pages/Events"));
const ContactUs = lazy(() => import("pages/ContactUs/ContactUs"));
const Logout = lazy(() => import("pages/Auth/Logout"));
const AccountDetails = lazy(() => import("pages/AccountDetails"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));

const Main = lazy(() => import("pages/Main"));
const MainRedesign = lazy(() => import("pages/MainRedesign/MainRedesign"));
const PlaceOrder = lazy(() => import("pages/PlaceOrder/PlaceOrder"));
const TermsConditionsStartupMD = lazy(() => import("pages/TermsConditionsDreamUps"));



function App() {
  const { details, loadingDetails } = useAppSelector((state) => state.user);
  const { loading } = useAppSelector((state) => state.global);
  const { token } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!details && token) {
      dispatch(getUserDetails());
    }
  }, []);

  if (!details && loadingDetails) {
    return (
        <SpinnerBackdrop />
    );
  }

  return (
    <>
      <SpinnerBackdrop loading={loading} />
      <Suspense fallback={<SpinnerBackdrop />}>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<MainRedesign />} />
            <Route path="home" element={<Home />} />
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="terms-conditions" element={<TermsConditions />} />
            <Route path="/terms-conditions/startup-moldova" element={<TermsConditionsStartupMD />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="event/:id" element={<Event />} />
            <Route path="events" element={<Events />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="account" element={<AccountLayout />}>
              <Route path="orders" element={<Orders />} />
              <Route path="details" element={<AccountDetails />} />
              <Route path="*" element={<Navigate to="/account/orders" />} />
              <Route path="/account" element={<Navigate to="/account/orders" />} />
            </Route>
            <Route path="/payment" element={<PaymentStatus />} />
            <Route path="logout" element={<Logout />} />
            <Route path="/error" element={<Error />} />
            <Route path="place-order" element={<PlaceOrder />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
