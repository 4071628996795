import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { useMemo, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { INotification, setMessage } from "../features/globalSlice";

interface ILocationStateProps {
  redirectUrl?: string;
}

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useMobileDetect = (defaultWidth = 860): boolean => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth <= defaultWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth <= defaultWidth,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return dimensions.width;
};

export const useNotification = () => {
  const dispatch = useAppDispatch();

  return (notification: INotification) => {
    dispatch(setMessage(notification));
  };
};

export const useRedirect = () => {
  let query = useQuery();
  const {state} = useLocation()
  const navigate = useNavigate()
  const queryRedirectUrl = query.get("redirectUrl");
  const routerRedirectUrl = (state as ILocationStateProps)?.redirectUrl;
  const isRedirecting = queryRedirectUrl || routerRedirectUrl;

  const redirectIfNecessary = () => {
    if (queryRedirectUrl) {
      window.location.href = queryRedirectUrl;
      return
    }

    if (routerRedirectUrl) {
      setTimeout(() => {
        navigate(routerRedirectUrl)
      }, 0)
    }
  }

  return {
    isRedirecting,
    redirectIfNecessary
  }

}
