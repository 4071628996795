import { toast } from "react-toastify";
import { TypeOptions } from "react-toastify";

interface Props {
  content: JSX.Element | string;
  type?: TypeOptions;
  time?: number;
}

export const Notification = ({ content, type = "success", time = 5000 }: Props) => {
  return toast(content, {
    type: type,
    position: "top-right",
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
