import "./style.scss";
import {ReactComponent as UndeLogo} from 'assets/unde/unde_light.svg'
import { slide as BurgerMenu, State } from "react-burger-menu";
import { Link } from "react-router-dom";
import sidebarSettings from "./config";
import SideBar from "../../SideBar";
import { useState } from "react";
import {Flex} from 'components/design-system/Flex/Flex'
import styled from 'styled-components'
import { useAppSelector } from "../../../hooks/hooks"

const LogoWrapper = styled(Flex)`
 svg {
   height: 36px;
 }
`

const MobileNav = () => {
  const [menuState, setMenuState] = useState<boolean>(false);

  const { details } = useAppSelector((state) => state.user);

  const menuItems = sidebarSettings(details?.isPartOfOrganization)

  const handleSidebarItemSelect = () => {
    setMenuState(false);
  };

  const handleStateChange = (state: State) => {
    setMenuState(state.isOpen);
  };
  return (
    <div className="mobile-navbar">
      <BurgerMenu
        pageWrapId={"app-content"}
        outerContainerId={"App"}
        isOpen={menuState}
        onStateChange={(state) => handleStateChange(state)}>
        <SideBar settings={menuItems} onItemSelect={handleSidebarItemSelect} />
      </BurgerMenu>
      <div className="mobile-navbar__logo">
        <Link to="/">
          <LogoWrapper>
            <UndeLogo/>
          </LogoWrapper>
        </Link>
      </div>
    </div>
  );
};

export default MobileNav;
