import request from "utils/request";
import { IFacebookLoginPayload, ILoginPayload, IRegisterPayload } from "types/auth";

export const login = (payload: ILoginPayload) => {
  return request({
    url: "/auth",
    method: "post",
    data: payload,
  });
};

export const register = (payload: IRegisterPayload) => {
  return request({
    url: "/signup",
    method: "post",
    data: payload,
  });
};

export const facebookLogin = (payload: IFacebookLoginPayload) => {
  return request({
    url: "/auth/facebook",
    method: "post",
    data: payload,
  });
};

export const googleLogin = (payload: IFacebookLoginPayload) => {
  return request({
    url: "/auth/google",
    method: "post",
    data: payload,
  });
};
