import React from "react";
import ReactDOM from "react-dom/client";
import Public from "./Public";
import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ErrorBoundary } from "react-error-boundary";
import Error from "pages/Error";
import { HelmetProvider } from "react-helmet-async";

import "./styles/index.scss";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <HelmetProvider>
    <Provider store={store()}>
      <BrowserRouter>
        <ToastContainer />
        <ErrorBoundary FallbackComponent={Error}>
          <Public />
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </HelmetProvider>
);
