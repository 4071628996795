import { useMobileDetect } from "../../hooks/hooks";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";

const Navbar = () => {
  const isMobile = useMobileDetect(980);
  return isMobile ? <MobileNav /> : <DesktopNav />;
};

export default Navbar;
