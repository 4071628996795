import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypeOptions } from "react-toastify";

export interface INotification {
  type: TypeOptions;
  message: string;
  time?: number;
}

type TGlobalState = {
  notification: INotification;
  loading: boolean;
};

const initialState: TGlobalState = {
  notification: {
    type: "default",
    message: "",
  },
  loading: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMessage: (state, { payload }: PayloadAction<INotification>) => {
      state.notification = payload;
    },
    clearMessage: (state) => {
      state.notification = initialState.notification;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
  },
});

const { reducer, actions } = globalSlice;
export const { setMessage, clearMessage, setLoading } = actions;
export const messageSelector = (state: { globalStore: TGlobalState }) => state.globalStore;
export default reducer;
